<template>
    <v-dialog v-model="show" :retain-focus="false" max-width="1000">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Edit System User</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.name" label="Name" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.email" label="Email" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="item.phone_number" 
                  label="Phone No." 
                  required
                  counter="10"
                  @keypress="onlyNumber" 
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete 
                  v-model="item.district" 
                  label="District" 
                  required 
                  :items="districtList"
                  item-text="district_title"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete 
                  v-model="item.designation" 
                  label="Designation" 
                  required 
                  :items="roleList"
                  item-text="role"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="item.hospital_id"
                  label="Eye Collection Center/Hospital"
                  required
                  :items="centerList"
                  item-text="hospital_name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="show = false"> Cancel </v-btn>
          <v-btn color="primary" @click="editSystemUser()" :disabled="!formIsValid"> Save </v-btn>
        </v-card-actions>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-card>
      
    </v-dialog>
</template>
<script>
import Editsystem from '@/service/System.service'
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export default {
  data(){
    return{
      roleList: [{ role: ""}],
      centerList: [{ id: "", hospital_name: ""}],
      districtList: [{ district_title: ""}],
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },

  watch: {
    options: {
      handler() {
        this.GetDistrictList()
        this.GetDesginationList()
        this.GetHospitalList()
      },
      deep: true,
    },
  },

  props: ['visible', 'item'],

  computed: {
    formIsValid () {
      return (
        this.item.name &&
        this.item.email.match(validRegex) &&
        this.item.phone_number &&
        this.item.designation &&
        this.item.district &&
        this.item.hospital_name
      )
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
        location.reload()
      },
    },
  },

  async mounted() {
    this.GetDistrictList()
    this.GetDesginationList()
    this.GetHospitalList()
  },

  methods:{

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9 ) {
          $event.preventDefault();
      }
    },

    async GetDistrictList() {
      const service = new Editsystem()
      let response = await service.DistrictList()
      if (response) {
        this.districtList = response.data
      } else {
        this.districtList = []
      }
    },

    async GetDesginationList() {
      const service = new Editsystem()
      let response = await service.DesginationList()
      if (response) {
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async GetHospitalList() {
      const service = new Editsystem()
      let response = await service.HospitalList()
      if (response) { 
        this.centerList = response.data
      } else {
        this.centerList = []
      }
    },
    
    async editSystemUser(){

      if(this.item.phone_number.length  < 10){
        return false,
        this.snackbarText = "Phone number should be 10 character.",
        this.snackbar = true
      }
      try {
          const data ={
              id: this.item.id,
              name: this.item.name,
              email: this.item.email,
              phone_number: this.item.phone_number,
              district:this.item.district,
              designation: this.item.designation,
              hospital_id: this.item.hospital_id,
              hospital_name: this.item.hospital_name,
          }
          const service = new Editsystem()
          const response = await service.updateSystem(data)
          if(response.status == 200){
            this.snackbarText = response.message,
            this.snackbar = true
            setTimeout(() => {
              this.show = false
            }, 1000)
            location.reload();
          }else{
            this.snackbarText = response.message,
            this.snackbar = true
          }
          
      } catch (e) {
          console.log(e)
          
      }
    }
  }
}

</script>